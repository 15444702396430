import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 
import { Link as ScrollLink } from 'react-scroll'; 
import './index.css';


const MenuItems = [
  {
    title: 'Products',
    path: '/products',
    cName: 'dropdown-link',
    subItems: [
      { title: 'AI', path: '/products', cName: 'dropdown-sublink' },
      { title: 'AR/VR', path: '/products', cName: 'dropdown-sublink' },
      { title: 'IoT', path: '/products', cName: 'dropdown-sublink' },
      { title: 'Blockchain', path: '/products', cName: 'dropdown-sublink' },
      
    ],
  },
  {
    title: 'Services',
    path: 'services-container', 
    cName: 'dropdown-link',
  },
];

function DropdownS() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItems.map((item, index) => (
          <li key={index} className="menu-item">
            {item.path.startsWith('/') ? (
              <Link
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                {item.title}
              </Link>
            ) : (
              <ScrollLink
                className={item.cName}
                to={item.path}
                smooth={true}
                duration={500}
                onClick={() => setClick(false)}
              >
                {item.title}
              </ScrollLink>
            )}
            {item.subItems && (
              <ul className="dropdown-submenu">
                {item.subItems.map((subItem, subIndex) => (
                  <li key={subIndex} className="submenu-item">
                    <Link
                      className={subItem.cName}
                      to={subItem.path}
                      onClick={() => setClick(false)}
                    >
                      {subItem.title}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

export default DropdownS;
