import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import './index.css'

const FAQComponent = () => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('ai');

  // FAQ data for each category
  const faqData = {
    ai: [
        {
            label: 'What is AI in PropTech?',
            content: 'AI in PropTech refers to the use of artificial intelligence technologies to enhance real estate services, from property management and development to investment analysis and client services. AI algorithms can automate tasks, analyze large datasets, predict market trends, and improve operational efficiencies within the real estate sector.'
        },
        {
            label: 'How can AI improve property management?',
            content: 'AI can streamline property management by automating routine tasks such as rent collection, tenant inquiries, and maintenance scheduling. It can also help in predicting maintenance needs using predictive analytics, ensuring that properties are well-maintained, and preventing costly repairs. Chatbots and virtual assistants can be deployed for customer service, enhancing tenant experiences.'
        },
        {
            label: 'Can AI help with property valuation?',
            content: 'Yes! AI can improve property valuation accuracy by analyzing vast amounts of data, including historical property prices, local market trends, economic indicators, and even neighborhood factors. Machine learning models can provide real-time, data-driven estimates of property value, making valuations faster and more precise than traditional methods.'
        },
        {
            label: 'How does AI impact real estate investment decisions?',
            content: 'AI tools can assist investors by analyzing large volumes of data from different sources (e.g., market trends, property performance, demographic data, etc.) to identify lucrative investment opportunities. AI can also forecast future trends and help investors make more informed decisions about potential returns on investment (ROI).'
        },
        {
            label: 'Can AI help in improving the home buying or renting experience?',
            content: 'Absolutely! AI can enhance the home buying and renting experience by offering personalized recommendations to clients based on their preferences. Chatbots can assist customers in real-time, and AI-powered platforms can match buyers or tenants with properties that suit their needs, budgets, and lifestyle. Virtual tours, powered by AI, can also provide immersive home-viewing experiences.'
        }
    ],
    arvr: [
      { label: "What are Augmented Reality (AR) and Virtual Reality (VR) in PropTech?", content: "Augmented Reality (AR) overlays digital elements, like images, 3D models, or information, onto the real world through devices like smartphones, tablets, or AR glasses. In Virtual Reality (VR), users are immersed in a fully digital environment, often using VR headsets, where they can interact with 3D models of properties or spaces. In PropTech, AR and VR enhance the property experience by providing immersive and interactive ways to visualize, explore, and market real estate." },
      { label: "How does Augmented Reality (AR) benefit property buyers and renters?", content: "AR helps property buyers and renters by allowing them to visualize how a space would look with different layouts, furniture, or décor in real time. Using a smartphone or tablet, potential buyers can “walk through” properties virtually, seeing how rooms look with different paint colors, furniture arrangements, or home improvements before committing. This also helps in understanding the scale and flow of a space." },
      { label: "How does Virtual Reality (VR) improve property tours?", content: "VR provides an immersive experience, allowing potential buyers or renters to take virtual property tours from anywhere in the world. With a VR headset, users can experience a property as if they were physically there, offering a more interactive and detailed tour than photos or videos. This is particularly useful for long-distance buyers or those unable to visit properties in person." },
      { label: "Can AR and VR be used for real estate marketing?", content: "Yes, both AR and VR are powerful tools for real estate marketing. AR can be used for interactive ads or brochures that provide additional content, such as 3D models of properties, when scanned with an app. VR offers virtual walkthroughs, allowing potential buyers to experience properties remotely. These immersive experiences can be shared online, on social media, or through VR headsets at real estate exhibitions, making it easier to engage and attract clients." },
      {label:"How does AR enhance home staging?",content:"AR enhances home staging by allowing potential buyers to visualize how a property would look with different furniture, paint colors, or design elements without physically altering the space. AR apps can show 3D furniture models or room layouts, helping buyers better imagine the potential of a home or apartment. This reduces the need for costly and time-consuming physical staging."}
    ],
    iot: [
        {
          label: "What is IoT in PropTech?",
          content:
            "IoT (Internet of Things) in PropTech refers to the integration of smart, connected devices in real estate properties to enhance the functionality, efficiency, and management of buildings. These devices collect and exchange data through the internet, enabling property managers, owners, and tenants to monitor, control, and optimize building operations, such as energy consumption, security, and maintenance.",
        },
        {
          label: "How does IoT improve property management?",
          content:
            "IoT improves property management by enabling real-time monitoring and control of various building systems. For example:\n" +
            "- **Energy management:** Smart thermostats, lighting, and HVAC systems adjust based on occupancy or time of day, reducing energy waste.\n" +
            "- **Predictive maintenance:** IoT sensors detect early signs of equipment failure, allowing for proactive repairs and reducing unexpected costs.\n" +
            "- **Security:** IoT-enabled cameras, locks, and access systems provide remote monitoring and enhanced security.",
        },
        {
          label: "How does IoT benefit tenants?",
          content:
            "For tenants, IoT provides a more convenient, comfortable, and efficient living or working environment. Examples include:\n" +
            "- **Smart home devices:** Automated lighting, temperature control, and voice-activated assistants.\n" +
            "- **Personalized comfort:** Adjustments to room conditions (e.g., temperature, lighting) can be made remotely through smartphones or voice commands.\n" +
            "- **Increased safety:** IoT sensors can detect smoke, gas leaks, or water leaks and alert tenants in real time.",
        },
        {
          label: "What role does IoT play in smart buildings?",
          content:
            "In smart buildings, IoT is the backbone of automation and data-driven decision-making. Through sensors and connected devices, IoT enables:\n" +
            "- **Efficient resource use:** Smart systems monitor and optimize energy use, reducing costs and environmental impact.\n" +
            "- **Automated operations:** IoT can control lighting, heating, cooling, and even elevators, improving building performance.\n" +
            "- **Data insights:** Real-time data from IoT devices provides valuable insights into building usage, helping property owners and managers optimize building design and operations.",
        },
        {
          label: "How can IoT help with energy efficiency in real estate?",
          content:
            "IoT improves energy efficiency by providing real-time monitoring of energy use across a building. Smart meters, lighting, and HVAC systems adjust based on occupancy and weather conditions, reducing unnecessary energy consumption. For example, sensors can turn off lights when rooms are unoccupied, or adjust heating/cooling systems based on the time of day or season. This leads to lower energy costs and a reduced carbon footprint.",
        },
      ],
  };

  const accordionData = faqData[selectedCategory];

  return (
    <div className="landing-page-screen-7-container">
    {/* Animated Background */}
    <div className="animated-bg"></div>
  
    <h1 className="landing-page-s7-heading">FAQs</h1>
    {/* Category Buttons */}
    <div className="landing-page-s7-category-buttons">
      <button
        className={`category-button ${selectedCategory === 'ai' ? 'active' : ''}`}
        onClick={() => setSelectedCategory('ai')}
      >
        AI in PropTech
      </button>
      <button
        className={`category-button ${selectedCategory === 'arvr' ? 'active' : ''}`}
        onClick={() => setSelectedCategory('arvr')}
      >
        AR & VR in PropTech
      </button>
      <button
        className={`category-button ${selectedCategory === 'iot' ? 'active' : ''}`}
        onClick={() => setSelectedCategory('iot')}
      >
        IoT in PropTech
      </button>
    </div>
    {/* Accordion */}
    <div className="landing-page-s7-accordion-consultation-con">
      <div className="landing-page-s7-accordion-con">
        {accordionData.map((accordion, index) => (
          <div className="landing-page-s7-accordion-item" key={index}>
            <div
              className="landing-page-s7-accordion-item-label-icon-con"
              onClick={() => setActiveAccordion(activeAccordion === index ? null : index)}
            >
              <h3 className="landing-page-s7-accordion-label">{accordion.label}</h3>
              <IoIosArrowDown
                className={`landing-page-s7-accordion-icon ${
                  activeAccordion === index ? 'active' : ''
                }`}
              />
            </div>
            <div
              className={`landing-page-s7-accordion-content ${
                activeAccordion === index ? 'active-accordion' : ''
              }`}
            >
              <p className="landing-page-s7-accordion-content-text">{accordion.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  )}  
export default FAQComponent;
