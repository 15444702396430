import AI from "../../components/Assets/ai.jpg";
import ARVR from '../../components/Assets/arandvr.jpg';
import IoT from '../../components/Assets/iot.jpg';
import TECH from '../../components/Assets/tech.jpg'

const Products = () => {

    return(
        <div>
              <div id="product-section">
        <h2 className="products-heading">Products</h2>
        <div className="products-row">
          <div className="product-column" >
            <h3>AI</h3>
            <img src={AI} alt="AI" />
          </div>
          <div className="product-column" >
            <h3>AR & VR</h3>
            <img src={ARVR} alt="AR & VR" />
          </div>
          <div className="product-column" >
            <h3>IoT</h3>
            <img src={IoT} alt="IoT" />
          </div>
          {/* <div className="product-column" onClick={() => handleProductClick('TECH')}>
            <h3>TECH</h3>
            <img src={TECH} alt="TECH" />
          </div> */}
        </div>
      </div> 
        </div>
    )
}

export default Products