import React from 'react';
import Navbar from '../Navbar'; 
import Navbottom from '../Navbottom';
import './index.css';

const Team = () => {
  const leadershipTeam = [
    { name: 'Maheswara Reddy', role: 'Founder and CEO', profile: 'CEO Profile Description', photo: 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg', linkedin: 'https://www.linkedin.com/in/mahesh-sm/' },
    { name: 'Sr Advocate', role: 'Chief Legal Officer', profile: 'Legal Advisor Profile Description', photo: 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg', linkedin: 'https://www.linkedin.com/ntadvocates/' },
    { name: 'Umadevi', role: 'Co-Founder', profile: 'Co-Founder Profile Description', photo: 'https://img.freepik.com/premium-vector/user-profile-icon-flat-style-member-avatar-vector-illustration-isolated-background-human-permission-sign-business-concept_157943-15752.jpg' }
  ];

  const renderTeam = (team, title) => (
    <div className="team-category">
      <h2>{title}</h2>
      <div className="team-members">
        {team.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.photo} alt={member.name} className="team-member-photo" />
            <h3>{member.name}</h3>
            <p>{member.role}</p>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
              <img
                src="https://img.freepik.com/premium-vector/linkedin-app-icon-world-s-largest-professional-network-social-networking-jobs-careers_277909-476.jpg"
                alt="LinkedIn"
                id='social-img-team'
                className='linkedin-icon'
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );

  const companyMessage = (
    <div className="company-message">
      <h2 className='company-heading'>Transforming Real Estate & Legal Automation with AI and Emerging Technologies</h2>
      <p>
        Welcome to the future of real estate and legal processes. At Proptelligence, we harness the power of AI and cutting-edge technologies to revolutionize how you interact with the property market and legal matters.
      </p>
      <ul>
        <li><strong>Streamlined Efficiency:</strong> Our AI-driven solutions simplify and automate complex tasks, from document analysis to property valuations. This means faster, more accurate services that save you time and money.</li>
        <li><strong>Enhanced Client Experience:</strong> With personalized AI assistance, our clients enjoy tailored property suggestions and instant support. Our technology ensures that every client interaction is smooth and satisfying.</li>
        <li><strong>Robust Compliance:</strong> Stay ahead of regulatory changes with our automated compliance checks and AI-powered contract analysis. We help you navigate the legal landscape with ease, ensuring all requirements are met.</li>
        <li><strong>Data-Driven Insights:</strong> Make informed decisions with real-time data and AI analytics. We provide insights into market trends, investment opportunities, and potential risks, empowering you to make strategic choices.</li>
        <li><strong>Innovative Solutions:</strong> Committed to continuous innovation, we explore the latest advancements to enhance our services. Our goal is to maintain our competitive edge and drive growth in the real estate and legal sectors.</li>
      </ul>
      <p>
        Join us in pioneering the future of real estate and legal automation. Discover how Proptelligence can elevate your experience with intelligent, efficient, and innovative solutions.
      </p>
      <p><strong>Maheswara Reddy,</strong><br />Founder and CEO</p>
    </div>
  );

  return (
    <>
      <Navbar />
      <div className='cont-team'>
        <div className="team-container">
          <h2>Meet Our Teams</h2>
          <p className='team-sub'>Our talented teams work together to drive success across different sectors.</p>
          {/* Leadership Team */}
          {renderTeam(leadershipTeam, "Leadership Team")} 
          {companyMessage}
        </div>
      </div> 
      <Navbottom />
    </>
  );
};

export default Team;
