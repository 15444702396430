import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase"; // Adjust the path according to your project structure
import Cookies from 'js-cookie'
import Navbottom from '../Navbottom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Navbar from '../Navbar';
import { Helmet } from 'react-helmet';
import { registerWithEmailAndPassword, signInWithGoogle } from "../../firebase"; 
import { IoIosArrowDown } from "react-icons/io";
import { IoFlashOutline } from "react-icons/io5";
import './index.css';
import popup from '../../components/Assets/popup.jpg'
import AI from "../../components/Assets/ai.jpg";
import ARVR from '../../components/Assets/arandvr.jpg';
import IoT from '../../components/Assets/iot.jpg';
import TECH from '../../components/Assets/tech.jpg'
import Popup from 'reactjs-popup';
import Legalservice from '../../components/Assets/legalservices.png'
import Propertyservice from '../../components/Assets/propertyservice.png'
import Propvaluation from '../../components/Assets/propvaluation.png'
import Propbot from '../../components/Assets/propbot.png';
import PropAutomatedPropertyManagement from '../../components/Assets/PropAutomatedpropertyManagement.png'
import PropLegal from '../../components/Assets/proplegal.png';
import PropLytics from '../../components/Assets/proplytics.png'
import Propvirtualtour from '../../components/Assets/propvirtualtour.png';
import Propvirtualdesign from '../../components/Assets/propvirtualdesign.png';
import Smart from '../../components/Assets/smart.png';
import Smarthome from '../../components/Assets/smarthome.png';
import Energyefficiency from '../../components/Assets/energyefficiency.png'; 
import FAQComponent from '../FAQComponent'



const Home = () => {
  const [showPopup, setShowPopup,] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [popupOptions, setPopupOptions] = useState([]);
  const [isOptionsPopupOpen, setIsOptionsPopupOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

const [activeAccordion, setActiveAccordion] = useState(0);


  const whatsappNumber = '+918062181169'; // Replace with the company WhatsApp number

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const sendMessage = () => {
    const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const hasSeenPopup = sessionStorage.getItem('hasSeenPopup');

    if (!hasSeenPopup) {
      setShowPopup(true);
      sessionStorage.setItem('hasSeenPopup', 'true');
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleProductClick = (product) => {
    if (!user && !jwtToken) {
      alert('Please login to access products');
      navigate('/login');
    } else {
      setSelectedProduct(product);
      switch (product) {
        case 'AI':
          setPopupOptions([
            { imageUrl: Propvaluation, heading: 'PropValuation' },
            { imageUrl: Propbot, heading: 'PropGpt' },
            { imageUrl: PropAutomatedPropertyManagement, heading: 'PropAutomatedPropertyManagement' },
            { imageUrl: PropLegal, heading: 'PropLegal' },
            { imageUrl: PropLytics, heading: 'Propanalytics' }
          ]);
          break;
        case 'AR & VR':
          setPopupOptions([
            { imageUrl: Propvirtualtour, heading: 'Prop360 Virtual Tour' },
            { imageUrl: Propvirtualdesign, heading: 'Prop Virtual Design' }
          ]);
          break;
        case 'IoT':
          setPopupOptions([
            { imageUrl: Smart, heading: 'PropSmart Building Management' },
            { imageUrl: Smarthome, heading: 'Prop SmartHome' },
            { imageUrl: Energyefficiency, heading: 'Prop EnergyEfficeny' }
          ]);
          break;
        case 'TECH':
          navigate('/tech');
          break;
        default:
          setPopupOptions([]);
      }
      setIsOptionsPopupOpen(true);
    }
  };
  

  const handleOptionClick = (option) => {
    console.log(`Option clicked: ${option}`);
    switch (option) {
      case 'PropValuation':
        navigate('/Propvaluation');
        break;
      case 'PropGpt':
        navigate('/Propbot');
        break;
      case 'PropAutomatedPropertyManagement':
        navigate('/PropAutomatedPropertyManagement');
        break;
      case 'Propanalytics':
        navigate('/propanalytics'); // Navigate to the page for AI
        break;
      case 'PropLegal':
        window.location.href = 'https://ntadvocates.in/';
        break;
      case 'Prop360 Virtual Tour':
        navigate('/virtualtour'); // Navigate to the page for AR & VR
        break;
      case 'Prop Virtual Design':
        navigate('/virtualdesign'); // Navigate to the page for AR & VR
        break;
      case 'PropSmart Building Management':
      case 'Prop SmartHome':
      case 'Prop EnergyEfficeny':
        navigate('/iotsolutions'); // Navigate to the page for IoT
        break;
      default:
        break;
    }
    setSelectedOption(null); // Close the popup after navigation
  };

  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [selectedCity, setSelectedCity] = useState('Bengaluru');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/contact'); // Navigate to the contact page
  };
  
  
    
  
  const [user] = useAuthState(auth);

  const cityImages = {
    Bengaluru: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1719286332/Bangalore_heatmap_nd32b0.png',
    Hyderabad: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1719247247/HyderabadHeatmap_ft5rlf.png',
    Ahemadabad: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1719285890/MicrosoftTeams-image_9_sgmb5u.png',
    Delhi : 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1719286002/MicrosoftTeams-image_11_jvudbu.png',
    kolkotha : 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1719286000/MicrosoftTeams-image_13_uqjmfm.png'
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const register = () => {
    if (!name) {
      alert("Please enter name");
      return;
    }

    // registerWithEmailAndPassword(name, email, password)
    //   .then(() => {
    //     setSignupSuccess(true); 
    //     setSignupError(null); 
    //   })
    //   .catch(error => {
    //     setSignupError(error.message); 
    //   });
  }; 

  


  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    const token = Cookies.get('jwt_token'); 
    console.log(token)
    setJwtToken(token);
  }, []);

  const checkAuthAndNavigate = (path) => {
    console.log('User:', user);         // Debug: Check user state
  console.log('JWT Token:', jwtToken);
    if (!user && !jwtToken) {
      alert('Please login to access services')
      navigate('/login');
    } else{
      navigate(path)
    }
  };

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
    );
  }

  const [imageUrl, setImageUrl] = useState(
    'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734780147/image_juaber.png'
  );

  const handleLocationChange = (event) => {
    const selectedLocation = event.target.value;
    const imageUrls = {
      india: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734780147/image_juaber.png',
      hyderabad: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734803778/image_3_ndpkoj.png',
      bengaluru: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734803827/image_4_a6zmep.png',
      chennai: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734805363/image_6_gyw1r9.png',
      pune: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734805336/image_5_brfief.png',
      mumbai: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734803393/image_1_wiuq1y.png',
      ahmedabad: 'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734803747/image_2_etl93a.png', 
      newDelhi :'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734849319/image_7_gdc1pt.png',
      kolkata:'https://res.cloudinary.com/ajaymedidhi7/image/upload/v1734849365/image_ildujt.jpg',

    };
    setImageUrl(imageUrls[selectedLocation]);
  };

  return (
    <>
    
      <Helmet> 
        <title> Proptelligence | Free Property Post in India | AI Powered Solutions For Real Estate and Legal | Online Legal Services  in India</title>
        <meta name="Legal Services" content="Proptelligence is an AI-powered real estate and legal platform that helps agents, attorneys, and other real estate professionals find and procure properties." /> 
        <meta name="Property Services" content="Navigating the legal complexities of real estate transactions can be daunting. Proptelligence simplifies this process by offering tailored legal services to meet your needs." /> 
        <meta name="Industries" content="Property Technologies has worked with a range of industries including commercial real estate, residential real estate, hospitality, and healthcare." />
        <meta name="About us" content="At Proptelligence, we recognize the challenges faced by property owners and strive to provide innovative solutions tailored to their needs." />
        <meta name="msvalidate.01" content="D2A150396A7BEE21CE9769C5C3479F1C" />
        <meta name="google-site-verification" content="google154aa1c8bf93db82.html" />
      </Helmet>
      <Navbar/>
    
      <div className="home-container">
  {/* Search Section */}
  <input
    type="search"
    className="search-input"
    placeholder="Search for property,services"
  />
   <h1 id="main-heading">
    The Complete Intelligence Platform on Infradevelopers, Agents & Individuals
  </h1>

 
  {/* Input Section */}
  <div className="input-section"> 
    <div className="input-row">
      <button className="action-button">Residential</button>
      <button className="action-button">Commercial</button>
    </div>
    <div className="input-row">
      <select className="location-dropdown" defaultValue="">
        <option value="" disabled>
          Country
        </option>
        <option value="india">India</option>
      </select>
      <select className="location-dropdown" defaultValue="">
        <option value="" disabled>
          State
        </option>
        {/* Add more options as needed */}
        <option value="karnataka">Karnataka</option>
      </select>
      <select className="location-dropdown" defaultValue="">
        <option value="" disabled>
          City
        </option>
        {/* Add more options as needed */}
        <option value="bengaluru">Bengaluru</option>
      </select>
    </div>
  </div>

  {/* Radio and Image Section */}
  <div className="home-container-2">
    {/* Radio Buttons */}
    <div className="radio-container">
      <label>
        <input type="radio" name="option" value="infradevelopers" />
        Infradevelopers
      </label>
      <label>
        <input type="radio" name="option" value="agents" />
        Agents
      </label>
      <label>
        <input type="radio" name="option" value="individuals" />
        Individuals
      </label>
    </div>

    {/* Image Section */}
    <img
      src={imageUrl}
      alt="Selected Location"
      className="home-image"
    />
  </div>
</div>

      <section className="features">
        {/* Synthetic Data */}
        <div className="feature-card">
          <div className="feature-icon synthetic-data"></div>
          <h2>Synthetic Data</h2>
          <p>
            Propelligence leverages synthetic data to create realistic simulations for training AI
            models, enabling accurate property valuations and risk assessments.
          </p>
        </div>

        {/* AI Solutions */}
        <div className="feature-card">
          <div className="feature-icon ai-solutions"></div>
          <h2>AI Solutions</h2>
          <p>
            Propelligence employs AI algorithms for automated property valuation, legal document
            analysis, and personalized recommendations to clients.
          </p>
        </div>

        {/* AR/VR Integration */}
        <div className="feature-card">
          <div className="feature-icon ar-vr"></div>
          <h2>AR/VR Integration</h2>
          <p>
            Propelligence integrates augmented and virtual reality technologies to provide immersive
            property tools, enabling clients to visualize spaces and make informed decisions.
          </p>
        </div>

        {/* IoT Connectivity */}
        <div className="feature-card">
          <div className="feature-icon iot"></div>
          <h2>IoT Connectivity</h2>
          <p>
            Propelligence utilizes IoT sensors to monitor property conditions, automate building
            management, and optimize energy consumption.
          </p>
        </div>
      </section>
      <section className="impact">
        <h2>Propelligence Impact</h2>
        <p>
          Propelligence harnesses the power of advanced technologies to create innovative solutions
          for real estate and legal industries, improving efficiency, accuracy, and customer
          experience.
        </p>
        <div className="content-section">
        <div className="column">
          <p className="highlight">Free property services</p>
          <p className="details">7+ cities</p>
          <p className="details">31+ states</p>
        </div>
        <div className="column">
          <p className="highlight">Products</p>
          <p className="details">15+</p>
        </div>
        <div className="column">
          <p className="highlight">Online legal services</p>
          <p className="details">10+</p>
        </div>
        </div>
      </section> 

      <div id="product-section">
        <h2 className="products-heading">Products</h2>
        <div className="products-row">
          <div className="product-column" onClick={() => handleProductClick('AI')}>
            <h3>AI</h3>
            <img src={AI} alt="AI" />
          </div>
          <div className="product-column" onClick={() => handleProductClick('AR & VR')}>
            <h3>AR & VR</h3>
            <img src={ARVR} alt="AR & VR" />
          </div>
          <div className="product-column" onClick={() => handleProductClick('IoT')}>
            <h3>IoT</h3>
            <img src={IoT} alt="IoT" />
          </div>
          {/* <div className="product-column" onClick={() => handleProductClick('TECH')}>
            <h3>TECH</h3>
            <img src={TECH} alt="TECH" />
          </div> */}
        </div>
      </div> 
      <div id='services-container'>
          <h1 className="service-heading">Trending Services</h1>
          <div className="service-info-home">
              <img
              src={Propertyservice}
              alt="Property Services"
              className="service-image"
              onClick={() => checkAuthAndNavigate('/select')}
              />
              <h1 className="service-details-title-home">Property Services</h1>
              <p className="service-content">At Proptelligence, we recognize the challenges faced by property owners and strive to provide innovative solutions tailored to their needs.</p>
            </div> 
            <div className="service-info-home">
              <img
              src={Legalservice}
              alt="Legal Services"
              className="service-image"
              onClick={() => checkAuthAndNavigate('/legalservices')}
              />
              <h1 className="service-details-title-home">Legal Services</h1>
              <p className="service-content">Navigating the legal complexities of real estate transactions can be daunting. Proptelligence simplifies this process by offering tailored legal services to meet your needs.</p>
            </div> 
        </div>
      <footer>
        <Navbottom />
      </footer> 

      {isOptionsPopupOpen && (
  <Popup open={isOptionsPopupOpen} closeOnDocumentClick onClose={() => setIsOptionsPopupOpen(false)}>
    <div className="options-popup">
      <h3>{selectedProduct}</h3>
      <ul>
        {popupOptions.map((option, index) => (
          <li key={index} onClick={() => handleOptionClick(option.heading)} className="popup-option-item">
            <img src={option.imageUrl} alt={option.heading} className="popup-option-image" />
            <span className="popup-option-heading">{option.heading}</span>
          </li>
        ))}
      </ul>
      <button onClick={() => setIsOptionsPopupOpen(false)} className="close-options-popup">&times;</button>
    </div>
  </Popup>
)}
      
    </>
  );
};
export default Home;